import { useMemo } from 'react';
import { isBefore } from 'date-fns';
import { useSiteInfoData } from 'store/siteInfo';
import { useMoodleAPI } from 'store/moodleAPI/useMoodleAPI';
import { lmsCategoryId } from 'store/moodleAPI/lmsCategoryId';
import {
  CoreEnrolGetUsersCoursesData,
  CoreEnrolGetUsersCoursesQuery
} from 'store/moodleAPI/moodleTypes/_functions/core_enrol_get_users_courses';
import {
  CoreCourseGetCategoriesData,
  CoreCourseGetCategoriesQuery
} from 'store/moodleAPI/moodleTypes/_functions/core_course_get_categories';

export enum CourseCompletionVariant {
  all = 'all',
  incomplete = 'incomplete'
}

export const useEnrolUserCoursesData = (variant: CourseCompletionVariant = CourseCompletionVariant.all) => {
  const siteInfoData = useSiteInfoData();
  const userid = siteInfoData?.userid;

  const { data: categoriesData, isLoading: isCategoriesLoading } = useMoodleAPI<
    CoreCourseGetCategoriesQuery,
    CoreCourseGetCategoriesData
  >(
    !!userid && {
      wsfunction: 'core_course_get_categories',
      criteria: [
        {
          key: 'id',
          value: lmsCategoryId
        }
      ]
    }
  );

  const { data: enrolCoursesData, isLoading: isCoursesLoading } = useMoodleAPI<
    CoreEnrolGetUsersCoursesQuery,
    CoreEnrolGetUsersCoursesData
  >(
    !!userid &&
      !!categoriesData?.length && {
        wsfunction: 'core_enrol_get_users_courses',
        userid: userid,
        returnusercount: 0
      }
  );

  const courses = useMemo(() => {
    const coursesAll =
      enrolCoursesData?.filter((course) => categoriesData?.find((category) => category.id === course.category)) || [];

    if (variant === CourseCompletionVariant.incomplete) {
      return coursesAll.filter(
        (course) =>
          !course.completed &&
          (course.completionhascriteria ? course.progress === 100 : true) &&
          (course.enddate ? isBefore(course.enddate, new Date()) : true)
      );
    }

    return coursesAll;
  }, [enrolCoursesData, variant, categoriesData]);

  return {
    coursesData: courses,
    isLoading: isCoursesLoading || isCategoriesLoading
  };
};
